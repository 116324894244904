import React from 'react'

const Component = ({ items }) => {
  return (
    <div className="wrap downloadable-files">
      {items.map((item, i) => (
        <Button key={i} content={item} />
      ))}
    </div>
  )
}

const Button = ({ content }) => {
  const { title, file, externalLink } = content

  let link = file?.file.url
  if (externalLink) link = externalLink

  return (
    <>
      {link && (
        <a className="button" href={link} target="_blank">
          {title}
        </a>
      )}
    </>
  )
}

export default Component
