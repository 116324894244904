import React from 'react'
import ContentBlock from 'components/contentBlock'
import Layout from 'components/layout'
import Title from 'components/title'
import DownloadableFiles from 'components/downloadableFiles'
import { graphql } from 'gatsby'
import { trFunction } from 'utils/functions'

const Care = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const page = data.thisPage
  let downloadableFiles = []
  if (data.downloadableFile) downloadableFiles.push(data.downloadableFile)

  return (
    <Layout>
      <div id="content">
        <Title title={page.title} />
        {downloadableFiles.length > 0 && (
          <DownloadableFiles items={downloadableFiles} />
        )}
        {page.contentBlocks.map((block, i) => (
          <ContentBlock key={i} order={i} block={block} page={page} />
        ))}
      </div>
    </Layout>
  )
}
export default Care

export const carePageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    downloadableFile: contentfulDownloadableFiles(slug: { eq: "care" }) {
      id
      title
      file {
        file {
          url
        }
      }
      externalLink
    }
    thisPage: contentfulPages(slug: { eq: "care" }) {
      title
      slug
      contentBlocks {
        id
        slug
        template
        label
        title
        subtitle
        textPosition
        content {
          id
          childMarkdownRemark {
            html
          }
        }
        accordionItems {
          ... on ContentfulAccordionItems {
            id
            title
            content {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        imagesFull {
          ...GatsbyImage
        }
        imagesFullMobile {
          ...GatsbyImage
        }
        imagesFullLink
        buttonLink
        imageSingle {
          ...GatsbyImage
        }
        video {
          wistiaCode
        }
        slides {
          images {
            ...GatsbyImage
          }
          caption
        }
      }
    }
  }
`
