import React from 'react'
import LinkWrap from 'components/LinkWrap'

const Title = (props) => {
  return (
    // <header className="page-title-header">
    //   <h1 className="page-title"><div className="wrap-title">{props.title}</div></h1>
    // </header>
    <header
      className={
        'page-title-header' +
        ' ' +
        (props.subtitle ? 'page-title-header-subtitle' : '') +
        ' ' +
        (props.transparent ? 'page-single-overlay' : '')
      }
    >
      {props.subtitle && (
        <div className="small-caps">
          {props.subtitleLink ? (
            <LinkWrap to={props.subtitleLink}>{props.subtitle}</LinkWrap>
          ) : (
            props.subtitle
          )}
        </div>
      )}

      <h1 className="page-title">
        <div className="wrap-title">{props.title}</div>
      </h1>
      <h1 className="page-title placeholder">
        <div className="wrap-title">{props.title}</div>
      </h1>

      {props.afterTitle && (
        <div
          className="small-caps"
          style={{ textAlign: 'center', color: 'rgb(74,74,74)' }}
        >
          {props.afterTitle}
        </div>
      )}
    </header>
  )
}

export default Title
